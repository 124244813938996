import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Header, StepContainer } from '../components/SignUpSteps/components';
import * as yup from 'yup';
import { styled } from '@mui/system';

interface Params {
  id: string;
  token: string;
  status: string;
}

interface PaymentResult {
  code: string;
  header: string;
  message: string;
  isError?: boolean;
}

const paymentResults: PaymentResult[] = [
  {
    code: 'confirmed',
    header: 'Payment Confirmed',
    message: 'Your payment is made successfully. We will send you an email soon with a receipt of payment.<br/>Thank you for your payment.',
    isError: false,
  },
  {
    code: 'timeout',
    header: 'Payment Processing',
    message:
      'The payment is taking longer than expected. We will send you an email when the payment is processed.<br/>Thank you for your payment.',
    isError: false,
  },
  {
    code: 'expired',
    message: 'The payment link has expired.',
    header: 'Payment Link Expired',
    isError: true,
  },
  {
    code: 'invalid',
    message: 'The payment link is not valid.',
    header: 'Invalid Payment Link',
    isError: true,
  },
];

const GreenBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.pressPayGreen.main,
  minHeight: theme.spacing(4),
}));

const Hero = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(30),
}));

const Message = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  textAlign: 'center',
  '&.error': {
    color: theme.palette.error.main,
  },
}));

const Page = () => {
  const [paymentResult, setPaymentResult] = useState<PaymentResult>();

  useEffect(() => {
    (async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const schema = yup.object({
        id: yup.string().uuid().optional(),
        token: yup.string().uuid().required(),
        status: yup.string().required(),
      });

      const params: Params = {
        id: urlSearchParams.get('id') ?? '',
        token: urlSearchParams.get('token') as string,
        status: urlSearchParams.get('status') as string,
      };

      const isValid = await schema.isValid(params);
      if (!isValid) {
        console.error('invalid params');
        return;
      }

      const result = paymentResults.find((x) => x.code === params.status.toLowerCase());
      if (typeof result === 'undefined') {
        setPaymentResult({
          code: 'none',
          message: 'Invalid result returned.',
          header: 'Invalid result',
          isError: true,
        });
      } else {
        setPaymentResult(result);
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>Payment Result - PressPay</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <StepContainer>
        {paymentResult && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
            <Header>{paymentResult.header}</Header>
            <Message
              dangerouslySetInnerHTML={{
                __html: paymentResult.message,
              }}
              className={`${paymentResult.isError === true ? 'error' : ''}`}
            ></Message>
          </Box>
        )}
      </StepContainer>
      <GreenBox />
    </>
  );
};

export default Page;
